import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import Header from "../components/header"

const Title = styled.p`
  padding-top: 27px;
  font-family: inherit  
  margin-top: 30px;
  margin-bottom: 30px;
  height: 24px;  
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #3D3C3C;
`

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Header />
      <div className="container1">
        <div className="container2">
          <div>
            <Title>{post.frontmatter.title}</Title>
          </div>
          <div
            className={"description"}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
